<template>
  <div class="main">
    <div class="title">小月经期日记App隐私政策</div>
    <p class="brief">小月经期日记App（以下简称小月日记App）尊重并保护所有使用服务用户的个人隐私权。小月日记App会按照本隐私权政策的规定使用和披露您的个人信息，并以高度的勤勉、审慎义务对待这些信息。
      除本隐私权政策另有规定外，在未征得您事先许可的情况下，小月日记App不会将这些信息对外披露或向第三方提供。 小月日记App会不时更新本隐私权政策。
      您在同意小月日记App服务使用协议之时，即视为您已经同意本隐私权政策全部内容。</p>

    <p class="text">本隐私权政策属于小月日记App服务使用协议不可分割的一部分。</p>

    <p class="h2">1. 适用范围</p>
    <ul>
      <li>a) 在您注册小月日记App帐号时，您根据小月日记App要求提供的个人注册信息；</li>
      <li>b)
        在您使用小月日记App网络服务，或访问小月日记App平台网页时，小月日记App自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
      </li>
      <li>c) 小月日记App通过合法途径从商业伙伴处取得的用户个人数据。</li>
    </ul>

    <p class="text">您了解并同意，以下信息不适用本隐私权政策：</p>
    <ul>
      <li>a) 您在使用小月日记App平台提供的搜索服务时输入的关键字信息；</li>
      <li>b) 小月日记App收集到的您在小月日记App发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</li>
      <li>c) 违反法律规定或违反小月日记App规则行为及小月日记App已对您采取的措施。</li>
    </ul>
    <p class="h2">2. 信息使用</p>
    <ul>
      <li>a)
        小月日记App不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和小月日记App（含小月日记App关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
      </li>
      <li>b) 小月日记App亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何小月日记App平台用户如从事上述活动，一经发现，小月日记App有权立即终止与该用户的服务协议。</li>
    </ul>
    <p class="h2">3. 信息披露</p>
    <p class="text">在如下情况下，小月日记App将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</p>
    <ul>
      <li>a) 经您事先同意，向第三方披露；</li>
      <li>b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</li>
      <li>c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</li>
      <li>d) 如您出现违反中国有关法律、法规或者小月日记App服务协议或相关规则的情况，需要向第三方披露；</li>
      <li>e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</li>
      <li>f) 在小月日记App平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，小月日记App有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</li>
      <li>g) 其它小月日记App根据法律、法规或者网站政策认为合适的披露。</li>
    </ul>
    <p class="h2">4. 信息存储和交换</p>
    <p class="text">
      小月日记App收集的有关您的信息和资料将保存在小月日记App及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或小月日记App收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>

    <p class="h2">5. 信息安全</p>
    <ul>
      <li>a)
        小月日记App帐号均有安全保护功能，请妥善保管您的用户名及密码信息。小月日记App将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
      </li>
      <li>b)
        在使用小月日记App网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是小月日记App用户名及密码发生泄露，请您立即联络小月日记App客服，以便小月日记App采取相应措施。
      </li>
    </ul>
    <p class="h2">6. 账户注销</p>
    <ul>
      <li>a) 非登录状态下，可以正常使用除服务器功能外的基本功能，用户数据保存在本机。</li>
      <li>b) 登录后可以在“我”->“头像”->“账号信息”页面下选择“退出登录”或“注销并清除账号”，并立即生效。</li>
      <li>c) 退出登录后，仍可使用除网络服务之外的本地功能。</li>
      <li>d) 注销之后，服务器上的该账号数据立即清除，不可恢复。</li>
    </ul>
    <p class="h2">7. 设备权限调用说明</p>
    <p class="h3">(1) 读写日历：</p>
    <ul>
      <li>a) 小月日记App为了更准时地对用户进行经期提醒，在用户明确同意授权的情况下，会将用户的提醒设置作为日程提醒写入Android日历中。</li>
      <li>b) 用户不同意使用Android日历的情况下，除“经期提醒“之外的其他功能可以正常使用。</li>
      <li>c) 小月日记App只会读取和写入经期提醒相关的日程。</li>
    </ul>
    <p class="h3">(2) 存储读写权限：</p>
    <p class="text">为了存储您的数据，以及需要备份到服务器或者从服务器恢复的备份，需要用到读写存储的权限。</p>
    <p class="h3">(3) 获取网络状态：</p>
    <p class="text">为了完成需要服务器支持的操作，比如登录和备份功能，需要检查网络状态。</p>
    <p class="h3">(4) 获取手机状态：</p>
    <p class="text">通过微信登录和分享时，需要获取当时的手机状态。</p>
    <p class="h3">(5) 通知权限：</p>
    <p class="text">为了对用户进行经期开始通知，需要使用通知权限。</p>
    <p class="h3">(6) 读取AndroidID:</p>
    <p class="text">为了支持用户在未登录时使用小月日记App，并保存经期记录，需要读取AndroidID，在运行期间用作用户标识。</p>
    <p class="h3">(7) 获取已安装App信息:</p>
    <ul>
      <li>a)为了支持微信账号登录，需要允许我们检测是否已安装【微信】App；</li>
      <li>b)为了支持跳转应用市场App信息页，便于更新版本和评论，需要允许我们检测是否已安装【应用市场】App；</li>
      <li>c)为了检测安装包签名是否正确，需要允许我们获取【小月日记】App的安装信息。</li>
      <li>d)为了支持支付宝支付，需要允许我们检测是否已安装【支付宝】App；</li>
    </ul>

    <p class="h2">8. 第三方 SDK 统计服务</p>
    <p class="text">为保证【小月日记】App相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）实现相关功能；
    我们会对第三方获取信息的软件开发包（SDK）进行严格的安全监测，以保护数据安全；
      我们在下表对【小月日记】App中接入的相关第三方开发包（SDK）进行列明。</p>
    <table>
      <tr>
        <th>SDK名称</th>
        <th>第三方公司名称</th>
        <th>收集个人信息类型</th>
        <th>使用目的</th>
        <th>隐私政策地址</th>
      </tr>
      <tr>
        <td>微信Open SDK</td>
        <td>深圳市腾讯计算机系统有限公司</td>
        <td>1.已安装微信APP信息；<br/>
          2.微信账户头像和昵称；<br/>
          3.用户在分享时主动选择的图片或内容；<br/>
          4.微信支付订单标识；<br/>
          5.本次支付是否成功的状态信息。</td>
        <td>1.微信登录；<br/>
          2.微信好友及朋友圈分享；<br/>
          3.微信支付。</td>
        <td><a href="https://support.weixin.qq.com/cgi-bin/mmsupport-bin/readtemplate?stype=&promote=&fr=〈=zh_cn&adtag=&check=false&nav=faq&t=weixin_agreement">点击查看</a></td>
      </tr>
      <tr>
        <td>支付宝SDK</td>
        <td>支付宝（杭州）信息技术有限公司</td>
        <td> 1. 不可变更设备唯一标识；<br/>
          2. 可变更设备唯一标识；<br/>
          3. 基本设备信息；<br/>
          4. 软件安装列表；<br/>
          5. 网络信息。</td>
        <td>支付。</td>
        <td><a href="https://render.alipay.com/p/c/k2cx0tg8">点击查看</a></td>
      </tr>
      <tr>
        <td>友盟统计分析SDK</td>
        <td>友盟同欣（北京）科技有限公司</td>
        <td> 1.设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）<br/>
          2.位置信息。<br/>
          5. 网络信息。</td>
        <td>进行APP运营统计与分析，通过采集位置信息提供反作弊服务。</td>
        <td><a href="https://www.umeng.com/page/policy">点击查看</a></td>
      </tr>
      <tr>
        <td>友盟推送SDK</td>
        <td>友盟同欣（北京）科技有限公司</td>
        <td> 1.设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）<br/>
          2.位置信息。<br/>
          5. 网络信息。</td>
        <td>进行APP消息推送，通过采集位置信息提供个性化推送服务</td>
        <td><a href="https://www.umeng.com/page/policy">点击查看</a></td>
      </tr>
      <tr>
        <td>友盟崩溃分析SDK</td>
        <td>友盟同欣（北京）科技有限公司</td>
        <td> 1.设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）<br/>
          2.位置信息。<br/>
          5. 网络信息。</td>
        <td>进行APP性能监测和崩溃问题分析。</td>
        <td><a href="https://www.umeng.com/page/policy">点击查看</a></td>
      </tr>
      <tr>
        <td>阿里百川SDK</td>
        <td>浙江淘宝网络有限公司、淘宝（中国）软件有限公司、杭州阿里巴巴广告有限公司</td>
        <td> 本机应用列表、设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）、运营商信息、网络状态信息、操作日志、设备运行进程信息</td>
        <td>支持跳转到手机淘宝打开商品详情 。</td>
        <td><a href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662649.1998907877.3.55ccbe486scYvy">点击查看</a></td>
      </tr>
    </table>
    <p class="text">本声明自更新之日起生效<br/>最近的更新日期：2023 年 09 月 20 日 <br/>天津英奇网络科技有限公司 <br/>联系邮箱：xiaoyueriji@163.com</p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.main {
  font-size: 14px;
  padding: 10px 15px;
  background-color: #ffffff;
}

.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0;
}

.h2 {
  font-size: 16px;
  font-weight: 700;
}

.h3 {
  font-size: 14px;
  font-weight: 700;
}
table, th, td {
  border-collapse: collapse;
  border: 1px solid #888888;
}

</style>
